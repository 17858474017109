<template>
  <div>
    <ComHeader menuKey="product"></ComHeader>
    <!-- banner -->
    <div class="common-banner">
      <div class="container ui acenter">
        <img
          class="banner-icon animate__animated animate__backInLeft"
          src="@/assets/images/product/product-index-icon.png"
          alt=""
        />
        <div class="f1 animate__animated animate__backInDown">
          <p class="banner-title">API服务</p>
          <p class="banner-content">
            中码科技发展（成都）有限公司提供标准化的API接口服务，包含：实人信息比对技术服务【两项+人像】、实名信息比对技术服务【两项】、运营商三要素、身份证有效期查询等接口，接口具备安全可信直连权威数据源，高效便捷秒级响应，数据安全，专网存储等特点，全部接口适用于众多业务模式及应用场景，业务方可根据己方实际情况进行选择。
          </p>
        </div>
      </div>
    </div>
    <!-- 产品 -->
    <div class="container product">
      <ul class="pro-list">
        <li
          class="pro-item hvr-grow-shadow"
          v-for="(item, index) in productList"
          :key="index"
          @click="jumpPage(item.id)"
        >
          <img :src="item.imgUrl" alt="" />
          <div v-html="item.name" class="product-content"></div>
        </li>
      </ul>
    </div>

    <!-- 服务范围 -->
    <!-- <div class="service-area">
      <div class="ser-title">我们的服务范围</div>

    </div> -->

    <ComFooter></ComFooter>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import ComHeader from "@/components/header.vue";
import ComFooter from "@/components/footer.vue";

export default defineComponent({
  components: { ComHeader, ComFooter },

  setup() {
    const router = useRouter();

    const jumpPage = (id) => {
      router.push({ path: "/detail", query: { id } });
    };

    const productList = ref([
      // {name:"银行卡舆情接口",imgUrl:require("@/assets/images/api-logo-card.png")},
      // {name:"手机号舆情接口",imgUrl:require("@/assets/images/api-logo-phone.png")},
      {
        id: "cardTwoItems",
        name: "实名信息比对技术服务<br/>【两项】",
        imgUrl: require("@/assets/images/api-logo-cardeno2.png"),
      },
      {
        id: "cardTwoItemsImg",
        name: "实人信息比对技术服务<br/>【两项+人像】",
        imgUrl: require("@/assets/images/api-logo-headpic.png"),
      },
      {
        id: "cardValidity",
        name: "身份证有效期查询",
        imgUrl: require("@/assets/images/api-logo-cardno.png"),
      },
      // {
      //   id: "cardOperator",
      //   name: "运营商三要素查询",
      //   imgUrl: require("@/assets/images/api-logo-operate.png"),
      // },
      // {name:"实我活体检测",imgUrl:require("@/assets/images/api-logo-auth.png")},
    ]);

    return {
      jumpPage,
      productList,
    };
  },
});
</script>

<style scoped lang="less">
.common-banner {
  background: url("../../assets/images/common-banner-bg.png") no-repeat;
  background-size: 100% 100%;
  .banner-icon {
    width: 255px;
    height: 200px;
    margin-right: 50px;
  }
}
.product {
  padding: 120px 0 200px;
  .pro-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .pro-item {
      width: 21%;
      border: 1px solid #dadada;
      text-align: center;
      margin-top: 50px;
      padding: 35px 10px;
      font-size: 18px;
      color: #142d70;
      cursor: pointer;
      img {
        width: 63px;
        height: 50px;
        margin-bottom: 15px;
      }
    }
  }
}
.service-area {
  padding: 80px;
  .ser-title {
    font-size: 29px;
    color: #142d70;
    text-align: center;
  }
}
</style>
<style scoped lang="less">
.mobile {
  .common-banner {
    background: url("../../assets/images/common-banner-bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 80px 20px 0;
    display: flex;
    align-items: center;
  }
  .product {
    padding:10px 20px 30px 20px;
    .pro-list {
      .pro-item {
        width:46%;
        margin-top:20px;
        .product-content {
          font-size: 12px;
        }
      }
    }
  }
}
</style>